// 经典客户-图片列表
<template>
  <div class="classicCustomer-atlas">
    <el-row class="atlas-list" :gutter="20">
      <el-col class="atlas-item" :span="6" v-for="(item,index) in data" :key="index">
        <div class="atlas-logo flex-center">
          <el-image :class="[type!='zlhzhb'?'atlas-image':'atlas-img']" :src="$base+item.logo"></el-image>
        </div>
        <p class="atlas-name" v-if="type!='zlhzhb'">{{item.name}}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getCase } from '@/api'
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters(['getNavItem'])
  },
  data () {
    return {
      type: '',
      data: []
    };
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      this.type = this.$route.name
      let { data } = await getCase(this.getNavItem.tag)
      this.data = data
    }
  },
};
</script>

<style scoped lang="less">
.atlas-item {
  margin-bottom: 25px;
  text-align: center;
  .atlas-logo {
    margin: 0 auto 10px;
    width: 100%;
    max-width: 216px;
    height: 102px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(67, 67, 67, 0.1);
    border-radius: 50px;
  }
  .atlas-name {
    text-align: center;
  }
}
</style>
